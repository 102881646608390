.my-account-main-container {
    background-color: #fff;
    width: 300px;
    min-height: 530px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    position: relative;
}

.my-account-main-container .my-account-content {
    position: absolute;
    width: 100%;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-title {
    margin: 25px 52px;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-title
    .my-account-main-container-title-text {
    font-family: "harmoniaSansReg";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order {
    display: flex;
    align-items: center;
    padding: 19px 0 19px 43px;
    cursor: pointer;
    width: 100%;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-icon {
    padding-right: 10px;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-left-icon {
    position: absolute;
    right: 23px;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-icon
    .my-accounts-icon {
    font-size: 16px;
    color: #777;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-icon
    .my-accounts-icon.logout-icon {
    color: #333;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-left-icon
    .my-accounts-icon {
    font-size: 16px;
    color: #777;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-text {
    font-family: "harmoniaSansReg";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #777777;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order
    .my-account-main-container-content-track-text.logout-text {
    color: #333;
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order.active-account {
    background: rgba(254, 130, 118, 0.1);
}

.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order.active-account
    .my-account-main-container-content-track-icon
    .my-accounts-icon,
.my-account-main-container
    .my-account-content
    .my-account-main-container-content
    .my-account-main-container-content-track-order.active-account
    .my-account-main-container-content-track-text {
    color: #fe8276;
}

@media only screen and (max-width: 1025px) {
    .my-account-main-container {
        width: 280px;
        /* border-radius: 15px; */
    }
}
@media only screen and (max-width: 780px) {
    .my-account-main-container {
        width: 250px;
        border-radius: 15px;
    }

    .my-account-main-container
        .my-account-content
        .my-account-main-container-title {
        margin: 20px 52px;
    }

    .my-account-main-container
        .my-account-content
        .my-account-main-container-content
        .my-account-main-container-content-track-order {
        padding: 15px 0 15px 30px;
    }
}
