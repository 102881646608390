.track-your-order-main-container {
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    position: relative;
}

.track-your-order-main-container .track-your-order-content {
    padding: 30px 45px;
    position: relative;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-title {
    margin-bottom: 23px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-title
    .track-your-order-title-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #333333;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body {
    display: flex;
    flex-direction: column;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left {
    width: 100%;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-header {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
    white-space: pre-line;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-header
    .staric-input {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ea2c2c;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-input {
    display: flex;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-input
    input {
    border: none;
    outline: none;
    color: #333;
    font-size: 14px;
    font-family: "Harmonia Sans", sans-serif;
    font-weight: 600;
    width: 316px;
    height: 56px;
    padding: 18px;
    background: #f6f6f6;
    border-radius: 4px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-input
    input:focus {
    border: 1px solid #f29d95;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .phone-validation-input
    input::placeholder {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .mobile-inuput-div {
    display: flex;
    flex-direction: column;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .opt-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 0 0;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .otp-buttton,
.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container {
    margin-top: 30px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .otp-buttton,
.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    .otp-inputs-container {
    width: 100%;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .otp-buttton,
.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    .otp-submit-buttton {
    width: 230px;
    height: 44px;
    background: #fe8276;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .otp-buttton.otp-buttton-disable {
    background-color: #fea29a;
    cursor: not-allowed;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .otp-buttton
    .otp-buttton-text,
.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    .otp-submit-buttton
    .otp-submit-buttton-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-left
    .phone-validation-container
    .invalid-mob-no {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #f44336;
    margin-top: 10px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-title-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    input {
    border: none;
    outline: none;
    background: #f6f6f6;
    border-radius: 4px;
    font-family: "Harmonia Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: 54px;
    height: 54px;
    padding: 0 20px;
    text-align: center;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    .otpInput {
    width: 54px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    input:focus {
    border: 1px solid #f29d95;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .otp-input-content
    input:not(:last-child) {
    margin-right: 8px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .invalid-otp-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.track-your-order-main-container
    .track-your-order-content
    .track-your-order-body
    .track-your-order-body-right
    .otp-input-container
    .resend-otp-button {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* letter-spacing: 0.5px; */
    text-decoration-line: underline;
    color: #fe8276;
    margin-top: 24px;
    cursor: pointer;
}

.track-your-order-main-container .otp-resend {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* letter-spacing: 0.5px; */
    width: 170px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #515151;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 0);
}

@media only screen and (max-width: 1300px) {
    /* .track-your-order-main-container {
        width: 660px;
    } */

    /* .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container {
        margin-top: 30px;
    } */
}

@media only screen and (max-width: 920px) {
    .track-your-order-main-container {
        border-radius: 10px;
    }
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body {
        flex-direction: column;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container {
        margin-left: 0px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input {
        flex-direction: column;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input
        .otp-buttton {
        width: 316px;
        margin-left: 0px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 830px) {
    .track-your-order-main-container .track-your-order-content {
        padding: 30px 35px;
    }
}

@media only screen and (max-width: 780px) {
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input
        input {
        width: 275px;
    }
}

@media only screen and (max-width: 550px) {
    .track-your-order-main-container {
        border-radius: 5px;
        /* width: 309px; */
        /* min-height: 154px; */
    }

    .track-your-order-main-container .track-your-order-content {
        padding: 25px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container {
        flex-direction: column;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-header {
        margin-bottom: 8px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input
        input {
        width: 260px;
        height: 44px;
        padding: 18px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .invalid-mob-no {
        margin-top: 8px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input
        .otp-buttton {
        width: 100%;
        height: 60px;
        background: #fe8276;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        cursor: pointer;
        position: fixed;
        /* top: 0; */
        left: 0;
        bottom: 0;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .otp-buttton,
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        .otp-submit-buttton {
        width: 100%;
        height: 60px;
        background: #fe8276;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        cursor: pointer;
        position: fixed;
        /* top: 0; */
        left: 0;
        bottom: 0;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container {
        margin-top: 14px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .mobile-inuput-div {
        align-items: flex-start;
        margin-top: 14px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        input {
        width: 44px;
        height: 44px;
        padding: 0 12px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        input:not(:last-child) {
        margin-right: 12px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .invalid-otp-text {
        font-size: 12px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .resend-otp-button {
        font-size: 12px;
        margin-top: 16px;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .opt-button-container,
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        .otp-submit-buttton {
        position: fixed;
        bottom: 0;
        left: 0;
        /* display: flex;
        justify-content: flex-end;
        margin: 40px 0 0 0; */
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .opt-button-container
        .otp-buttton,
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        .otp-submit-buttton {
        width: 100%;
        border-radius: 0;
        /* font-weight: 700;
        font-size: 16px; */
    }

    .track-your-order-main-container .otp-resend {
        position: fixed;
        top: 85%;
    }
}

@media only screen and (max-width: 330px) {
    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-left
        .phone-validation-container
        .phone-validation-input
        input {
        width: 100%;
    }

    .track-your-order-main-container
        .track-your-order-content
        .track-your-order-body
        .track-your-order-body-right
        .otp-input-container
        .otp-input-content
        input {
        margin: 5px 0;
    }
}
