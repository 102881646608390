@font-face {
    font-family: "HarmoniaSansProCyr-Bold";
    src: url("./assests/fonts/HarmoniaSansProCyr-Bold.ttf");
}

@font-face {
    font-family: "HarmoniaSansProCyr";
    src: url("./assests/fonts/HarmoniaSansProCyr-Regular.ttf");
}

@font-face {
    font-family: "harmoniaSansReg";
    src: url("./assests/fonts/harmoniaSansReg.otf");
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
