.order-list-main-container {
    width: 100%;
}

.order-list-main-container .shipment-status {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #00cc6a;
    padding-bottom: 25px;
    text-transform: uppercase;
}

.order-list-main-container .shipment-status.shipment-status-cancelled {
    color: #f44336;
}

.order-list-main-container .shipment-status.shipment-status-undelivered {
    color: blue;
}

.order-list-main-container .order-list-content {
    width: 100%;
    min-height: 177px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 20px;
}

.order-list-main-container .order-list-content .order-list-body {
    padding: 28px 0 0 0;
}

.order-list-main-container .order-list-content .order-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 28px 0 28px;
}

.order-list-shipment-container-mobile {
    display: none;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header {
    padding: 0 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left {
    display: flex;
    align-items: center;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left
    .box-logo,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left
    .cart-logo {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    background: rgba(255, 232, 217, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left
    .box-logo
    .box-icon,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left
    .cart-logo
    .cart-icon {
    color: #fe8276;
    font-size: 30px;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left
    .oder-id-and-mob-no
    .order-id,
.order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left
    .oder-id-and-mob-no
    .order-id {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 14px;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left
    .oder-id-and-mob-no
    .order-mob-no,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left
    .oder-id-and-mob-no
    .order-mob-no {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-left
    .oder-id-and-mob-no
    .order-mob-no
    span,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-left
    .oder-id-and-mob-no
    .order-mob-no
    span {
    color: #333333;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-right
    .order-list-shipment-container
    .order-list-track-icon
    .order-list-track-logo,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header-right
    .order-list-invoice-container
    .order-list-invoice-icon
    .invoice-icon {
    color: #fff;
    font: 15px;
    margin-right: 7.5px;
}

.order-list-main-container
    .order-list-content
    .order-list-header
    .order-list-header-right
    .order-list-shipment-container
    .order-list-shipment-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fe8276;
    text-decoration: underline;
    cursor: pointer;
}

.order-list-main-container .order-list-content .order-list-header-divider-line {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    margin: 14px 0 0 0;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container {
    display: flex;
    align-items: center;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-delivery-lable,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-lable,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-date-and-payment
    .order-placed-container
    .order-placed-lable,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .products-list-title {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
    white-space: nowrap;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-deliver-date,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-date-and-payment
    .order-placed-container
    .order-placed-date {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-left: 8px;
    white-space: nowrap;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-lable-div {
    margin-left: 88px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-lable-div,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-delivery-lable-div {
    display: flex;
    align-items: center;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #00cc6a;
    margin-left: 12px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-text.order-list-status-text-cancelled {
    color: #f44336;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-left
    .order-list-delivery-container
    .order-list-status-text.order-placed {
    color: #4d94ff;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-right
    .order-list-order-details {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-right
    .order-list-order-details
    .order-list-cart-icon,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-right
    .order-list-order-details
    .order-list-down-icon {
    color: #fe8276;

    font-size: 15px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-right
    .order-list-order-details
    .order-list-details-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fe8276;
    margin: 0 10px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-status
    .order-list-status-right
    .order-list-shipment-mobile-container {
    display: none;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion {
    padding-top: 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-list-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-date-and-payment {
    display: flex;
    margin-top: 28px;
    padding: 0 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .order-date-and-payment
    .order-placed-container {
    display: flex;
    align-items: center;
    margin-right: 60px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container {
    margin-top: 28px;
    padding-top: 28px;
    border-top: 1px solid #e5e5e5;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .products-list-title {
    margin-bottom: 10px;
    padding: 0 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left {
    display: flex;
    align-items: center;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left
    .products-photo {
    margin-right: 35px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left
    .products-photo
    img {
    width: 65px;
    object-fit: cover;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left
    .product-info
    .product-info-name {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    padding-right: 10px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left
    .product-info
    .product-info-name.product-info-name-mob {
    display: none;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-left
    .product-info
    .product-info-quantity {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
    margin-top: 11px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .list-of-products
    .list-of-products-row
    .list-of-products-row-right
    .product-info-price {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    white-space: nowrap;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .products-list-container
    .order-list-invoice-container-mobile {
    display: none;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price {
    margin-top: 13px;
    padding: 28px;
    border-top: 1px solid #e5e5e5;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price
    .tax-and-price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price
    .tax-and-price-row:not(:last-child) {
    padding-bottom: 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price
    .tax-and-price-row
    .tax-and-price-row-label {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price
    .tax-and-price-row
    .tax-and-price-row-amount {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .shipment-details-container
    .tax-and-price
    .tax-and-price-row
    .tax-and-price-row-amount.tax-and-price-row-discount-amount {
    color: #00cc6a;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container {
    padding: 28px;
    border-top: 1px solid #e5e5e5;
    display: flex;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container
    .billing-address-container-left {
    width: 50%;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container
    .billing-address-container-left
    .billing-address-label,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container
    .billing-address-container-left
    .payment-status
    .payment-status-value {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container
    .billing-address-container-left
    .payment-status
    .payment-status-label,
.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .billing-address-container
    .billing-address-container-left
    .user-details
    .user-details-name {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
    padding: 8px 40px 8px 0;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .chat-with-us-container {
    padding: 28px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .chat-with-us-container
    .chat-with-us-content {
    background: rgba(254, 130, 118, 0.1);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .chat-with-us-container
    .chat-with-us-content
    .chat-with-us-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .chat-with-us-container
    .chat-with-us-content
    .chat-with-us-button {
    width: 135px;
    height: 34px;
    background: #fe8276;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    cursor: pointer;
}

.order-list-main-container
    .order-list-content
    .order-list-body
    .order-list-details-accordion
    .chat-with-us-container
    .chat-with-us-content
    .chat-with-us-button
    .chat-with-us-button-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
}

@media only screen and (max-width: 780px) {
    .order-list-main-container .order-list-content .order-list-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header
        .order-list-header-right {
        display: none;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header
        .order-list-header-left {
        padding: 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status {
        flex-direction: column;
        align-items: stretch;
        padding: 0 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container {
        width: 100%;
        align-items: stretch;
        padding: 0 22px 0 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header-left {
        margin-top: 7px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container {
        margin-top: 22px;
        padding-top: 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-status-lable-div,
    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-delivery-lable-div {
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-status-lable-div {
        margin-left: 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-deliver-date,
    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-date-and-payment
        .order-placed-container
        .order-placed-date {
        margin-left: 0;
        margin-top: 5px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-status-text {
        margin-left: 0;
        margin-top: 5px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-status-text.order-placed {
        color: #4d94ff;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-order-details {
        width: 100%;
        height: 50px;
        justify-content: center;
        border-top: 1px solid #e5e5e5;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-shipment-mobile-container {
        display: block;
        /* padding: 0 20%; */
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-shipment-mobile-container
        .order-list-shipment-mobile-content {
        padding: 10px 22px 22px 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header
        .order-list-header-left
        .oder-id-and-mob-no
        .order-id,
    .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header-left
        .oder-id-and-mob-no
        .order-id {
        margin-bottom: 7px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-shipment-mobile-container
        .order-list-shipment-mobile-content
        .order-list-track-icon {
        margin-right: 8px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-shipment-mobile-container
        .order-list-shipment-mobile-content
        .order-list-track-icon
        .order-list-track-logo {
        font-size: 15px;
        color: #fe8276;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-right
        .order-list-shipment-mobile-container
        .order-list-shipment-mobile-content
        .order-list-shipment-text {
        font-family: "Harmonia Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-decoration: underline;
        color: #fe8276;
    }

    /*  Accordion styling  */

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion {
        padding-top: 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header-right {
        display: none;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-date-and-payment {
        justify-content: space-between;
        margin-top: 22px;
        padding: 0 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-date-and-payment
        .order-placed-container {
        width: 50%;
        margin-right: 0;
        flex-direction: column;
        align-items: flex-start;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .products-list-title {
        margin-bottom: 0;
        padding: 0 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row {
        padding: 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .shipment-details-container
        .tax-and-price {
        margin-top: 0px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-left
        .products-photo {
        margin-right: 16px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-right
        .product-info-price {
        margin-left: 40px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .order-list-invoice-container-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 56px;
        border-top: 1px solid #e5e5e5;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .order-list-invoice-container-mobile
        .order-list-invoice-icon {
        margin-right: 8px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .order-list-invoice-container-mobile
        .order-list-invoice-icon
        .invoice-icon {
        font-size: 15px;
        color: #fe8276;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .order-list-invoice-container-mobile
        .order-list-invoice-text {
        font-family: "harmoniaSansReg";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-decoration-line: underline;

        color: #fe8276;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .billing-address-container {
        flex-direction: column;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .billing-address-container
        .billing-address-container-left {
        width: 100%;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .billing-address-container
        .billing-address-container-left:first-child {
        padding-bottom: 28px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .chat-with-us-container {
        padding: 0 0 22px 0;
    }
}
@media only screen and (max-width: 480px) {
    .order-list-main-container {
        width: 100%;
    }

    .order-list-main-container .order-list-content {
        width: 100%;
    }

    .order-list-main-container .shipment-status {
        /* font-size: 18px; */
        padding-bottom: 20px;
    }

    .order-list-main-container .order-list-content .order-list-body {
        padding: 15px 0 0 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header
        .order-list-header-left
        .box-logo,
    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header-left
        .cart-logo {
        width: 43px;
        height: 43px;
        margin-right: 18px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header
        .order-list-header-left
        .box-logo
        .box-icon,
    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .order-list-details-header-left
        .cart-logo
        .cart-icon {
        font-size: 20px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-header-divider-line {
        margin: 0;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-status
        .order-list-status-left
        .order-list-delivery-container
        .order-list-status-lable-div {
        margin-left: 0;
    }

    /*  Accordion styling  */

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-left
        .products-photo
        img {
        width: 32px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-left
        .product-info
        .product-info-name {
        display: none;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-left
        .product-info
        .product-info-name.product-info-name-mob {
        display: block;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .products-list-container
        .list-of-products
        .list-of-products-row
        .list-of-products-row-left
        .product-info
        .product-info-quantity {
        margin-top: 2px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .shipment-details-container
        .tax-and-price {
        padding: 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .shipment-details-container
        .tax-and-price
        .tax-and-price-row:not(:last-child) {
        padding-bottom: 14px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .billing-address-container {
        padding: 22px;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .chat-with-us-container
        .chat-with-us-content {
        height: 67px;
        padding: 0 22px;
        justify-content: space-between;
    }

    .order-list-main-container
        .order-list-content
        .order-list-body
        .order-list-details-accordion
        .chat-with-us-container
        .chat-with-us-content
        .chat-with-us-button {
        width: 110px;
    }
}
