.otp-model-main-container {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.43);
    position: absolute;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-model-main-container .otp-model-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 16px;
    padding: 30px 40px;
    max-width: 400px;
}

.otp-model-main-container .otp-model-content .model-logo-div .success-icon {
    width: 94px;
    height: 94px;
    color: #06c686;
}

.otp-model-main-container .otp-model-content .model-logo-div .binoculars-icon {
    color: #fe8276;
    width: 60px;
    height: 50px;
}

.otp-model-main-container .otp-model-content .opt-verified-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    margin-top: 30px;
}

.otp-model-main-container .otp-model-content .order-error-text {
    font-family: "Harmonia Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    text-align: justify;
    /* width: 430px; */
    margin-top: 30px;
}

@media only screen and (max-width: 480px) {
    .otp-model-main-container .otp-model-content {
        width: 266px;
        min-height: 160;
        border-radius: 10px;
        padding: 30px 30px;
    }

    .otp-model-main-container .otp-model-content .model-logo-div .success-icon {
        width: 58px;
        height: 60px;
    }

    .otp-model-main-container .otp-model-content .opt-verified-text {
        margin-top: 15px;
    }

    .otp-model-main-container .otp-model-content .order-error-text {
        width: auto;
    }
}
