.width-100-percentage {
    width: 100%;
}

.order-tarck-page-main-container {
    min-height: 100vh;
}

.order-tarck-page-main-container .order-tarck-page-header img {
    object-fit: cover;
}

.order-tarck-page-main-container .order-tarck-page-header img.mobile-navbar {
    display: none;
}

.order-tarck-page-main-container .order-tarck-page-body {
    background-color: #f6f6f6;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 60px;
}

.order-tarck-page-main-container
    .order-tarck-page-body
    .order-tarck-page-body-content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 200px; /* temporary remove it later*/
    /* background-color: red; */
}

.order-tarck-page-main-container
    .order-tarck-page-body
    .order-tarck-page-body-content
    .order-tarck-page-track-your-order {
    /* margin-left: 30px; */
    width: 50%;
}

.order-tarck-page-main-container
    .order-tarck-page-body
    .order-tarck-page-body-content
    .order-tarck-page-order-list {
    width: 50%;
    /* margin-left: 60px; */
}

@media only screen and (max-width: 1025px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content {
        width: 95%;
    }

    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-order-list {
        width: 80%;
        /* margin-left: 60px; */
    }
}

@media only screen and (max-width: 920px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content {
        width: 80%;
    }

    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-order-list {
        width: 100%;
    }
}

@media only screen and (max-width: 830px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content {
        width: 89%;
    }

    /* .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-order-list {
        margin-left: 20px;
    } */
}

@media only screen and (max-width: 1300px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-track-your-order {
        width: 70%;
    }
}

@media only screen and (max-width: 1025px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-track-your-order {
        width: 90%;
    }
}

@media only screen and (max-width: 920px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-track-your-order {
        width: 100%;
    }
}

/* Surface Duo is mobile 540px */

@media only screen and (max-width: 550px) {
    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content {
        width: 85%;
    }

    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-my-account {
        display: none;
    }

    .order-tarck-page-main-container .order-tarck-page-header img {
        display: none;
    }

    .order-tarck-page-main-container
        .order-tarck-page-header
        img.mobile-navbar {
        display: flex;
    }

    .order-tarck-page-main-container .order-tarck-page-body {
        padding-top: 16px;
    }

    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content {
        padding-bottom: 0;
        justify-content: center;
    }

    .order-tarck-page-main-container
        .order-tarck-page-body
        .order-tarck-page-body-content
        .order-tarck-page-order-list {
        /* margin-left: 0; */
        width: 100%;
    }
}
